import styled from "styled-components"
import { ReactComponent as Close } from '../../assets/svgIcon/listView/Close.svg'

export const GeneralModal = ({ closeModal, btnName, children, titleOfModal, handleModalWork, widthOfGeneralModal, isChecked, isCancelButttonShow }) => {
    return (
        <Container width={widthOfGeneralModal} >
            <h1>{titleOfModal}
                <span onClick={closeModal}> <Close /></span>
            </h1>
            <div className="generalContent">
                {children}
            </div>
            <div className="createMilestone">
                {isCancelButttonShow && <button onClick={closeModal} >Cancel</button>}
                {btnName && <button onClick={handleModalWork} disabled={isChecked === undefined ? false : (isChecked ? false : true)} className="create">{btnName}</button>}
            </div>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column; 
    width: ${(props) => props.width || '50vh'} ;
    left: 0;
    right: 0;
    margin: auto;
    background-color: white;
    border: 1px solid var(--PEARL, #ECF1F4);
    border-radius: 4px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    
    h1{
        height: 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 16px;
        color: var(--DARK-GREY, #0E0E2C);
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 171.429% */
        border-radius: 4px 4px 0px 0px;
        border-bottom: 1px solid #E9EDF5;
        background: #F9FAFD;
        
        span{
            cursor: pointer;
        }
    }

    >.generalContent{
        flex: 1;
        padding: 8px 16px;
    }

    >.createMilestone{
        display: flex;
        justify-content: end;
        padding: 0 16px 8px;
        gap: 8px;

        button{
            border: 1px;
            padding: 4px 16px;
            border-radius: 4px;
        }
        .create{
            color: white;
            background: var(--BLUE, #2060EA);

            &:disabled{
                background-color: var(--MIST, #f7f8f9);
                color: var(--character-disabled-placeholder-25, rgba(0, 0, 0, 0.25));
            }

        }

    }
    .buttonGroup{
        display: flex;
        justify-content: end;
        gap: 16px
    }


`
