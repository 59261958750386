import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  transactions: { count: 0, results: [] },
  transactionsParams: { limit: 10, offset: 0 },
  reportForPurchase: null,
  reportConfigForPurchase: null,
  listOfPivotConfig: null,
  status: null,
  warehouse: null,
  supplier: null,
  products: null,
  reportInExcel: null,
};

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setTransactions: (state, action) => {
      state.transactions = action.payload;
    },
    resetTransactions: state => {
      state.transactions = initialState.transactions;
    },
    resetTransactionsParams: state => {
      state.transactionsParams = initialState.transactionsParams;
    },
    setTransactionsParams: (state, action) => {
      state.transactionsParams = action.payload;
    },
    setPurchaseReport: (state, action) => {
      state.reportForPurchase = action.payload;
    },
    setConfigReportForPurchase: (state, action) => {
      state.reportConfigForPurchase = action.payload;
    },
    setListOfPivotConfig: (state, action) => {
      state.listOfPivotConfig = action.payload;
    },
    setOrderStatus: (state, action) => {
      state.status = action.payload;
    },
    setOrderSupplier: (state, action) => {
      state.supplier = action.payload;
    },
    setOrderWarehouse: (state, action) => {
      state.warehouse = action.payload;
    },
    setListOfProducts: (state, action) => {
      state.products = action.payload;
    },
    setReportExcelData: (state, action) => {
      state.reportInExcel = action.payload;
    },

  },
});

export const {
  setTransactions,
  resetTransactions,
  resetTransactionsParams,
  setTransactionsParams,
  setPurchaseReport,
  setConfigReportForPurchase,
  setListOfPivotConfig,
  setPurchaseOrderStatus,
  setOrderStatus,
  setOrderSupplier,
  setOrderWarehouse,
  setListOfProducts,
  setReportExcelData,
} = reportSlice.actions;
export default reportSlice.reducer;
